<nav class="navbar desktop" [ngClass]="{'dark-mode': theme === 'dark-mode'}">
  <div class="navbar-logo">
    <img *ngIf="theme === 'dark-mode'" src="assets/logo.png" alt="Logo" class="logo" />
    <img *ngIf="theme !== 'dark-mode'" src="assets/logo_contrast.png" alt="Logo" class="logo" />
  </div>
  <ul class="navbar-links">
    <li><a routerLink="/" routerLinkActive="active">Home</a></li>
    <li><a routerLink="/about" routerLinkActive="active">About</a></li>
    <li><a routerLink="/gallery" routerLinkActive="active">Gallery</a></li>
    <li><a routerLink="/s_visualizer" routerLinkActive="active">Sorting visualizer</a></li>
    <li><a routerLink="/hueshifter" routerLinkActive="active">Hue shifter</a></li>
  </ul>
  <div id="dark-mode-toggle">
    <i class="fas fa-sun"></i>
    <mat-slide-toggle color="primary" (change)="setDarkMode($event.checked)" [checked]="theme === 'dark-mode'"></mat-slide-toggle>
    <i class="fas fa-moon"></i>
  </div>
</nav>

<nav class="navbar mobile" [ngClass]="{'dark-mode': theme === 'dark-mode'}">
  <div class="navbar-logo">
    <img *ngIf="theme === 'dark-mode'" src="assets/logo.png" alt="Logo" class="logo" />
    <img *ngIf="theme !== 'dark-mode'" src="assets/logo_contrast.png" alt="Logo" class="logo" />
  </div>
  <button class="navbar-toggle" (click)="toggleMenu()">
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </button>
  <ul class="navbar-links" [class.active]="menuOpen">
    <li><a routerLink="/" routerLinkActive="active" (click)="closeMenu()">Home</a></li>
    <li><a routerLink="/about" routerLinkActive="active" (click)="closeMenu()">About</a></li>
    <li><a routerLink="/gallery" routerLinkActive="active" (click)="closeMenu()">Gallery</a></li>
    <li><a routerLink="/s_visualizer" routerLinkActive="active" (click)="closeMenu()">Sorting visualizer</a></li>
    <li><a routerLink="/hueshifter" routerLinkActive="active" (click)="closeMenu()">Hue shifter</a></li>
    <div id="dark-mode-toggle-mobile">
      <i class="fas fa-sun"></i>
      <mat-slide-toggle color="primary" (change)="setDarkMode($event.checked)" [checked]="theme === 'dark-mode'"></mat-slide-toggle>
      <i class="fas fa-moon"></i>
    </div>
  </ul>
</nav>


