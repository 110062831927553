import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit {
  menuOpen = false;


  constructor() {
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  @HostBinding('class')
  get theme() {
    return (localStorage.getItem('colorTheme') || 'light') + '-mode';
  }

  ngOnInit(): void {
  }

  public setDarkMode(val: boolean) {
    if (val) {
      localStorage.setItem('colorTheme', 'dark');
    } else {
      localStorage.setItem('colorTheme', 'light');
    }
  }

}
